import Location from "support/location";
import Cookies from "support/cookies";
import Logger from "support/consoleLogger";
import Interval from "support/interval";
import WebWorkerIntervalSupplier from "support/webWorkerIntervalSupplier";
import Timeout from "support/timeout";
import EventBus from "support/eventBus";

import Tokens from "oauth/tokens";

import translations from "i18n/translations";

import LoginState from "state/loginState";
import AuthContext from "state/authContext";
import ActiveTab from "state/activeTab";
import FirstEntrance from "state/firstEntrance";
import PreviousUserStorage from "state/previousUserStorage";
import Storage from "state/storage";
import BrowserId from "state/browserId";

import InactivityBanner from "idle/inactivityBanner";
import ActivityTracker from "idle/activityTracker";
import SessionExpired from "idle/sessionExpired";

import AuthActions from "auth/authActions";
import BrowserClosedWithoutLogout from "auth/browserClosedWithoutLogout";
import AuthLogging from "auth/authLogging";
import AuthInterceptor from "auth/authInterceptor";

import TokenRefresh from "refresh/tokenRefresh";
import UsernameChanged from "refresh/usernameChanged";
import FrameCommunication from "refresh/frameCommunication";
import ReferrerStore from "referrer/referrerStore";
import AuthConfig from "./authConfig";

function createInterval() {
	let intervalSupplier;
	if (window.applicationFeatures && window.applicationFeatures["ui.useWebWorkerTimers"]) {
		try {
			intervalSupplier = new WebWorkerIntervalSupplier(window);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	}
	if (intervalSupplier == null) {
		intervalSupplier = window;
	}

	return new Interval(intervalSupplier);
}

export default function (config) {
	translations.setLang("da");

	const interval = createInterval();

	const eventBus = new EventBus(config.keepEventsForForwarder);
	const timeout = new Timeout(window);
	const location = new Location(window.location, window.history);
	const cookies = new Cookies(document);
	const log = new Logger(window.console, config.debug);
	const storage = new Storage(log, config.clearStorageOnLogoutExclusions);
	const browserId = new BrowserId(storage);

	const authConfig = new AuthConfig(config, browserId);
	const tokens = new Tokens(authConfig);

	const previousUserStorage = new PreviousUserStorage(cookies, config.previousUserExpiration);
	const loginState = new LoginState(storage);
	const firstEntrance = new FirstEntrance(storage);
	const activityTracker = new ActivityTracker(authConfig, eventBus, interval);
	const authContext = new AuthContext(activityTracker, tokens, storage, previousUserStorage);
	const activeTab = new ActiveTab(storage, interval, log);
	const authActions = new AuthActions(authContext, loginState, authConfig, location, log);

	const browserClosedWithoutLogout = new BrowserClosedWithoutLogout(!config.skipAutoLogout, authContext, activeTab, firstEntrance);
	const sessionExpired = new SessionExpired(authContext, authActions, interval, loginState, log);
	const usernameChanged = new UsernameChanged(authContext, interval, location, log);

	const inactivityBanner = new InactivityBanner(eventBus, document);

	const authLogger = new AuthLogging(authContext, authConfig, location, log, config.trackErrorFn, browserId);
	const authInterceptor = new AuthInterceptor(tokens, authContext, loginState, location, eventBus, authLogger);
	const tokenRefresh = new TokenRefresh(authContext, authConfig, activeTab, loginState, location, authLogger, interval, timeout, eventBus, document);
	const frameCommunication = new FrameCommunication(eventBus, window);
	const referrerStore = new ReferrerStore(config, document, storage);

	this.startApp = () => {
		firstEntrance.checkOnStartup();
		frameCommunication.start();
		log.debug("Auth context - ", authContext.debugInfo());
		log.debug("Browser Closed Without Logout - ", browserClosedWithoutLogout.debugInfo());
		log.debug("Login state - ", loginState.debugInfo());

		if (browserClosedWithoutLogout.isLogoutNeeded()) {
			authActions.redirectToLogoutPageAndReturnToCurrentPage(firstEntrance.didOccurred());
			return false;
		}

		const isReturningFromLoginPage = authInterceptor.isReturningFromLoginPage();
		if (isReturningFromLoginPage) {
			const currentLogin = loginState.currentLogin();
			authInterceptor.processUrl();
			if (currentLogin.isRefresh || currentLogin.isRelogin) {
				// don't start application when returning from login page during token refresh
				return false;
			}
			if (authContext.isNotLogged()) {
				// auth server response didn't cause user to login, he needs to logout and login again
				authActions.redirectToLoginPage();
				return false;
			}
		}

		if (authContext.isNotLogged()) {
			authContext.logout();
			referrerStore.setCurrentReferrer();
			authActions.redirectToLoginPage();
			return false;
		}

		referrerStore.setCurrentReferrer();

		activeTab.track();
		activityTracker.track();

		sessionExpired.track();
		usernameChanged.track();
		inactivityBanner.init();
		tokenRefresh.start();

		if (isReturningFromLoginPage && authContext.usernameIsChangedAfterLogin()) {
			location.replaceHistoryPath(config.defaultWelcomePath);
		}

		return true;
	};

	this.registerEventForwarder = (forwarder) => {
		eventBus.registerForwarder(forwarder);
	};
	this.logout = (reason) => {
		authActions.redirectToLogoutPageAndReturnToMainPage(false, reason);
	};
	this.referrerStore = referrerStore.api;
	this.context = authContext;
}
