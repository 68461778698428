export default function (intervalSupplier) {
	const interval = (fn, milliseconds) => {
		const id = intervalSupplier.setInterval(fn, milliseconds);
		return { id };
	};
	interval.cancel = (promise) => {
		if (promise) {
			intervalSupplier.clearInterval(promise.id);
		}
	};

	return interval;
}
